import styled from 'styled-components'

import Threshold from 'constants/threshold'
import BackButton from 'components/common/BackButton'

export const PageContainer = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 88%;
  padding-top: 130px;
  margin: 0 auto;
  min-height: calc(100vh - 500px);

  & .error-message {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 39px;
  }

  @media (min-width: ${Threshold.phone}) {
    padding-top: 167px;
    min-height: calc(100vh - 260px);

    & .error-message {
      font-size: 20px;
      margin-bottom: 60px;
    }
  }
`

export const BackButtonContainer = styled(BackButton)`
  margin: 0 58px 0 57px;
  width: 260px;
  height: 40px;

  @media (min-width: ${Threshold.phone}) {
    width: 305px;
    height: 54px;
  }
`

export const StatusCodeWrapper = styled.div`
  width: 194px;
  height: 73px;
  margin-bottom: 45px;

  @media (min-width: ${Threshold.phone}) {
    width: 388px;
    height: 146px;
    margin-bottom: 41px;
  }
`
