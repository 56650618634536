import Link from 'next/link'
import React from 'react'

import { ButtonStyled } from './styles'

export interface ButtonProps {
  big?: boolean
  disabled?: boolean
  submit?: boolean
  href?: string
  link?: string
  target?: string
  rel?: string
  style?: any
}

const BackButton: React.FC<ButtonProps> = ({ children, submit, href, link, target, rel, style, ...rest }) => {
  return (
    <Link href={link} shallow>
      <ButtonStyled type={submit ? 'submit' : 'button'} {...rest}>
        {children}
      </ButtonStyled>
    </Link>
  )
}

export default BackButton
