import React from 'react'

import Layout from 'components/common/Layout'
import NotFoundError from 'components/common/NotFoundError'
import { BreadcrumbJsonLd, NextSeo } from 'next-seo'

const Custom404 = () => {
  return (
    <Layout menuButtonColor="black">
      <>
        <NextSeo
          title="Ошибка"
          canonical="https://newopen.su/404"
          openGraph={{
            url: `https://newopen.su/404`,
            title: `Ошибка | NEW / OPEN`
          }}
        />
        <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: 'Главная',
              item: 'https://newopen.su/'
            },
            {
              position: 2,
              name: 'Ошибка',
              item: 'https://newopen.su/404'
            }
          ]}
        />
        <NotFoundError />
      </>
    </Layout>
  )
}

export default Custom404
