import React from 'react'

import { BackButtonContainer, PageContainer, StatusCodeWrapper } from './styles'

const NotFoundError = () => {
  return (
    <PageContainer>
      <StatusCodeWrapper>
        <img src="/svgs/404.svg" width="100%" />
      </StatusCodeWrapper>
      <span className="error-message">Извините, мы не можем найти страницу, которую вы ищете!</span>
      <BackButtonContainer link="/">Вернуться на главную</BackButtonContainer>
    </PageContainer>
  )
}

export default NotFoundError
